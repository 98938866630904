<template>
    <div class="container">
        <div class="heading">
            <h2 class="title">Features Overview</h2>
        </div>
        <div class="overview-grid">
            <div class="item">
                <div class="icon">
                    <img src="~@/assets/images/icon-legal.svg" alt="">
                </div>
                <h3 class="sub-title">Legal Compliance</h3>
                <p>Ensure compliance with all global privacy laws effortlessly. Our banner keeps your site aligned with all privacy regulations, without the hassle of complicated setups or monthly fees, so you can have total peace of mind.</p>
            </div>
            <div class="item">
                <div class="icon">
                    <img src="~@/assets/images/icon-interests.svg" alt="">
                </div>
                <h3 class="sub-title">Better User Experience</h3>
                <p>Enhance your visitors' experience by allowing them to save their cookie preferences. Once saved, they won't encounter the consent banner on future visits, minimizing interruptions and ensuring a more seamless browsing experience on your site and thousands of others.</p>
            </div>
            <div class="spacer"></div>
            <div class="item">
                <div class="icon">
                    <img src="~@/assets/images/icon-currency.svg" alt="">
                </div>
                <h3 class="sub-title">Earn Recurring Revenue</h3>
                <p>Turn your legal compliance into a new source of recurring revenue. Everytime users upgrade, you lock in monthly recurring commissions - and this is net-new recurring revenue on top of any of your other monetization efforts from things such as ads and affiliates.</p>
            </div>
            <div class="item">
                <div class="icon">
                    <img src="~@/assets/images/icon-settings.svg" alt="">
                </div>
                <h3 class="sub-title">Easy to Setup and Free Forever</h3>
                <p>Our cookie banner can be set up and installed to your site in just minutes. Not only that, there is no set up costs or monthly fees - it's completely free for life!</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FeaturesOverview'
}
</script>

<style scoped> </style>