<template>
    <div class="container">
        <div class="heading">
            <h2 class="title">How It Works</h2>
        </div>
        <div class="work-steps">
            <div class="step-row">
                <div class="icon">
                    <img src="~@/assets/images/icon-preferences.svg" alt="">
                </div>
                <div class="panel">
                    <h4 class="title">Declare <br>Preferences</h4>
                    <p>When a visitor arrives on your site, they declare their cookie preferences through our banner. They will also have an option to save these preferences so they'll never see the banner again—improving their overall experience on your site and thousands of others.</p>
                </div>
            </div>
            <div class="step-row">
                <div class="icon">
                    <img src="~@/assets/images/icon-create.svg" alt="">
                </div>
                <div class="panel">
                    <h4 class="title">Create Free Privacy Bee Account</h4>
                    <p>If they opt to save their preferences, users will create a free Privacy Bee account. This ensures their choice is always remembered and enhances their browsing experience any time they visit your site in the future.</p>
                </div>
            </div>
            <div class="step-row">
                <div class="icon">
                    <img src="~@/assets/images/icon-scan.svg" alt="">
                </div>
                <div class="panel">
                    <h4 class="title">Scan For Personal Privacy Exposures</h4>
                    <p>As a cybersecurity, all Privacy Bee accounts will receive a complementary privacy exposure scan in the days following their account creation. If we find any of their personal data exposed online, we will notify them and provide a free guide on how to clean it up.</p>
                </div>
            </div>
            <div class="step-row last">
                <div class="icon">
                    <img src="~@/assets/images/icon-earn.svg" alt="">
                </div>
                <div class="panel">
                    <h4 class="title">Earn Commission From Upgrades</h4>
                    <p>If we find any exposures, many users choose to let us handle their data cleanup due to its complexity. If they upgrade to our paid services, you'll earn a recurring commission—creating compounding revenue for your site, while ensuring privacy compliance and enhancing your site's user experience.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="hexa-anim-wrap">
        <div class="hexagon" id="hexagon1"></div>
        <div class="hexagon" id="hexagon2"></div>
        <div class="hexagon" id="hexagon3"></div>
        <div class="hexagon" id="hexagon4"></div>
        <div class="hexagon" id="hexagon5"></div>
    </div>
</template>

<script>
export default {
    name: 'HowItWorks'
}
</script>

<style scoped> </style>