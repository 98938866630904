<template>
    <div class="container">
        <div class="pb-logo-footer">
            <img src="~@/assets/images/privacy-bee-logo-footer.png" alt="PrivacyBee" width="200" height="40">
        </div>
        <div class="copyright-block">
            &copy; <span id="copyright"></span> Privacy Bee, LLC. All rights reserved.
        </div>
        <div class="footer-links">
            <ul>
                <li><a href="mailto: pierce@privacybee.pub" target="_blank">Have a question?</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SiteFooter',
    mounted (){
        document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))
    }
}
</script>

<style scoped> </style>