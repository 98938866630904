<template>
    <div class="container">
        <div class="heading">
            <h2 class="title">Frequently Asked Questions</h2>
        </div>
        <div class="accordion">
            <div class="accordion__intro">
                <h4>Do I need a cookie consent banner?</h4>
                <div class="arrow">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path d="M9.25 7.79712L1.75 15.2971L0 13.5471L5.75 7.79712L0 2.04712L1.75 0.297119L9.25 7.79712Z"/>
                    </svg>
                </div>
            </div>
            <div class="accordion__content">
                <p>In most cases, yes. Cookie consent is a legal requirement to get visitors' permission before placing cookies on their devices. This consent is typically gathered through a cookie banner where users can accept or decline cookies. Regulations like the GDPR, LGPD, and POPIA mandate this consent because cookies and trackers can store personal data and identify individuals.</p>
            </div>
        </div>

        <div class="accordion">
            <div class="accordion__intro">
                <h4>How does cookie consent work?</h4>
                <div class="arrow">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path d="M9.25 7.79712L1.75 15.2971L0 13.5471L5.75 7.79712L0 2.04712L1.75 0.297119L9.25 7.79712Z"/>
                    </svg>
                </div>
            </div>
            <div class="accordion__content">
                <p>The cookie consent banner records users' choices and sets cookies accordingly: ‘accepting all’ cookies enables all cookies, ‘rejecting all’ cookies allows only essential ones, and ‘customizing preferences’ sets only the chosen cookie categories.</p>
            </div>
        </div>

        <div class="accordion">
            <div class="accordion__intro">
                <h4>How does Vendor &amp; Cookie Consent generate revenue?</h4>
                <div class="arrow">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path d="M9.25 7.79712L1.75 15.2971L0 13.5471L5.75 7.79712L0 2.04712L1.75 0.297119L9.25 7.79712Z"/>
                    </svg>
                </div>
            </div>
            <div class="accordion__content">
                <p>After a user declares their preferences, they will also be asked if they would like to save their preferences, so they never see the cookie banner pop up again on your site and thousands of others, improving user experience. If they choose to save their preferences, they will create a free Privacy Bee account in order to do so.</p>
                <p>As a cyber security company, over the next few days we will run a complementary scan of their digital footprint and alert them if any of their personal information is exposed online. As a courtesy, we will send them a free step by step guide on how to remove any of their exposed information from the internet. If they decide to upgrade to a Privacy Bee membership and let us handle the clean up for them, you will automatically receive a commission on a recurring basis.</p>
            </div>
        </div>

        <div class="accordion">
            <div class="accordion__intro">
                <h4>Is Vendor &amp; Cookie Consent customizable?</h4>
                <div class="arrow">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path d="M9.25 7.79712L1.75 15.2971L0 13.5471L5.75 7.79712L0 2.04712L1.75 0.297119L9.25 7.79712Z"/>
                    </svg>
                </div>
            </div>
            <div class="accordion__content">
                <p>We are happy to work with you to customize the banner to match the look and feel of your website - free of charge!</p>
            </div>
        </div>

        <div class="accordion">
            <div class="accordion__intro">
                <h4>How much does Vendor &amp; Cookie Consent cost?</h4>
                <div class="arrow">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path d="M9.25 7.79712L1.75 15.2971L0 13.5471L5.75 7.79712L0 2.04712L1.75 0.297119L9.25 7.79712Z"/>
                    </svg>
                </div>
            </div>
            <div class="accordion__content">
                <p>100% Free forever. Our cookie banner is one of the only ones that is completely free, no matter how big your site. And the best part - it also earns you money!</p>
            </div>
        </div>

        <div class="accordion">
            <div class="accordion__intro">
                <h4>How much will I earn using Vendor &amp; Cookie Consent?</h4>
                <div class="arrow">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                        <path d="M9.25 7.79712L1.75 15.2971L0 13.5471L5.75 7.79712L0 2.04712L1.75 0.297119L9.25 7.79712Z"/>
                    </svg>
                </div>
            </div>
            <div class="accordion__content">
                <p>On average, publishers are seeing around $19k/year for every 1m monthly unique visits to their website . This number is recurring and compounding over time and is in addition to any of your other site monetization efforts. These numbers can vary from site to site.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FaqContent',
    mounted() {
        const accordions = document.querySelectorAll(".accordion");

        const openAccordion = (accordion) => {
            const content = accordion.querySelector(".accordion__content");
            accordion.classList.add("accordion__active");
            content.style.maxHeight = content.scrollHeight + "px";
        };

        const closeAccordion = (accordion) => {
            const content = accordion.querySelector(".accordion__content");
            accordion.classList.remove("accordion__active");
            content.style.maxHeight = null;
        };

        accordions.forEach((accordion) => {
            const intro = accordion.querySelector(".accordion__intro");
            const content = accordion.querySelector(".accordion__content");

            intro.onclick = () => {
                if (content.style.maxHeight) {
                    closeAccordion(accordion);
                } else {
                    accordions.forEach((accordion) => closeAccordion(accordion));
                    openAccordion(accordion);
                }
            };
        });
    }
}
</script>

<style scoped> </style>