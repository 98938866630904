<template>
    <div class="container">
        <h3 class="title">Stay Compliant</h3>
        <div class="marquee-wrapper">
            <div class="logo-wrapper">
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-cpra.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/united-states.png" alt="US">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-cpa.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/united-states.png" alt="US">
                    </div>
                </div>
                <div class="logo-block generic">
                    <div class="brand-logo">
                        PDP
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/argentina.png" alt="Argentina">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-utah.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/united-states.png" alt="US">
                    </div>
                </div>
                <div class="logo-block generic">
                    <div class="brand-logo">
                        FADP
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/switzerland.png" alt="Switzerland">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-virginia.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/united-states.png" alt="US">
                    </div>
                </div>
                <div class="logo-block generic">
                    <div class="brand-logo">
                        APA
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/australia.png" alt="Australia">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-gdpr.svg" alt="">
                    </div>
                    <div class="flag lg">
                        <img src="~@/assets/images/flag/eu.png" alt="EU">
                        <img src="~@/assets/images/flag/united-kingdom.png" alt="UK">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-electronic.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/canada.png" alt="Canada">
                    </div>
                </div>
                <div class="logo-block generic">
                    <div class="brand-logo font-sm">
                        Amended <br>APPI
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/japan.png" alt="Japan">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-lgpd.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/brazil.png" alt="Brazil">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-cnil.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/france.png" alt="France">
                    </div>
                </div>
                <div class="logo-block generic">
                    <div class="brand-logo">
                        PDPA
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/thailand.png" alt="Thailand">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-pipa.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/south-korea.png" alt="South Korea">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-pdpa.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/singapore.png" alt="Singapore">
                    </div>
                </div>
            </div>
            <div class="logo-wrapper">
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-cpra.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/united-states.png" alt="US">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-cpa.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/united-states.png" alt="US">
                    </div>
                </div>
                <div class="logo-block generic">
                    <div class="brand-logo">
                        PDP
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/argentina.png" alt="Argentina">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-utah.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/united-states.png" alt="US">
                    </div>
                </div>
                <div class="logo-block generic">
                    <div class="brand-logo">
                        FADP
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/switzerland.png" alt="Switzerland">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-virginia.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/united-states.png" alt="US">
                    </div>
                </div>
                <div class="logo-block generic">
                    <div class="brand-logo">
                        APA
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/australia.png" alt="Australia">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-gdpr.svg" alt="">
                    </div>
                    <div class="flag lg">
                        <img src="~@/assets/images/flag/eu.png" alt="EU">
                        <img src="~@/assets/images/flag/united-kingdom.png" alt="UK">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-electronic.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/canada.png" alt="Canada">
                    </div>
                </div>
                <div class="logo-block generic">
                    <div class="brand-logo font-sm">
                        Amended <br>APPI
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/japan.png" alt="Japan">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-lgpd.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/brazil.png" alt="Brazil">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-cnil.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/france.png" alt="France">
                    </div>
                </div>
                <div class="logo-block generic">
                    <div class="brand-logo">
                        PDPA
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/thailand.png" alt="Thailand">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-pipa.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/south-korea.png" alt="South Korea">
                    </div>
                </div>
                <div class="logo-block">
                    <div class="brand-logo">
                        <img src="~@/assets/images/logo/logo-pdpa.svg" alt="">
                    </div>
                    <div class="flag">
                        <img src="~@/assets/images/flag/singapore.png" alt="Singapore">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StayCompliant'
}
</script>

<style scoped> </style>