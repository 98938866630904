<template>
    <div class="container">
        <div class="heading">
            <h2 class="title">Revenue Forecast</h2>
            <p class="lead">How many daily unique visitors does your site receive?</p>
        </div>
        <div class="revenue-calculator">
            <!-- Calc -->
            <div class="range-wrap">
                <input type="range" id="range-visitor" class="range" value="6500000" min="100000" max="100000000" step="100000" name="range">
                <div class="range-data">
                    <span class="min-value">&lt; 100k Monthly Uniques</span>
                    <span class="max-value">100M+</span>
                </div>
                <output class="bubble" id="range-bubble"></output>
            </div>

            <div class="range-data-top">
                <div class="top-cols">
                    <div class="col-left">
                        <p class="lead">Your Estimated Earnings: *</p>
                        <div class="commison">
                            <h4 class="data" id="insufficient-traffic"><strong id="yearly-commison"></strong> Per Year <sub>(<span id="monthly-commison"></span> Per Month)</sub></h4>
                            <h4 class="data" id="insuff-msg"></h4>
                        </div>
                    </div>
                    <div class="col-right">
                        <a href="#request-invitation" class="btn btn-border"><strong>Book Demo</strong></a>
                    </div>
                </div>
                <p class="disclaimer">* Forecasted estimates. Actual revenue will fluctuate based on industry and visitor composition</p>
            </div>

            <div class="range-data-bottom">
                <div class="col-left">
                    <h3 class="title">And the best part? <strong>It's recurring!</strong></h3>
                    <p>A 45 second visitor could drive revenue for years to come!</p>
                    <p>All commissions earned compound annually on renewals, starting slow but eventually overtaking most other transactional monetization strategies, like your ads!</p>
                </div>
                <div class="col-right">
                    <div class="data-block-row">
                        <div class="data-block first">
                            <p class="da-heading">Year 1 Revenue:</p>
                            <h4 class="data">
                                <strong id="year-1-commison"></strong>
                                <strong id="dash-1"></strong>
                            </h4>
                        </div>
                        <div class="data-block second">
                            <p class="da-heading">Year 2 Revenue:</p>
                            <h4 class="data">
                                <strong id="year-2-commison"></strong>
                                <strong id="dash-2"></strong>
                            </h4>
                        </div>
                    </div>
                    <div class="data-block-row last">
                        <div class="data-block third">
                            <p class="da-heading">Year 3 Revenue:</p>
                            <h4 class="data">
                                <strong id="year-3-commison"></strong>
                                <strong id="dash-3"></strong>
                            </h4>
                        </div>
                        <div class="data-block fourth">
                            <p class="da-heading">Year 4 Revenue:</p>
                            <h4 class="data">
                                <strong id="year-4-commison"></strong>
                                <strong id="dash-4"></strong>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <!-- // Calc -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'RevenueForecast',
    mounted() {
        function calculateVisitors(value) {
            //const yearly  = Math.round(value * 0.00985);
            const yearly  = Math.round(value * 0.019208);
            const monthly =  Math.round(yearly/12);
            const commisonTwoYear = Math.round((yearly * 190) / 100);
            const commisonThreeYear = Math.round((yearly * 280) / 100);
            const commisonFourYear = Math.round((yearly * 450) / 100);

            document.getElementById('yearly-commison').textContent = '$' + yearly.toLocaleString();
            document.getElementById('monthly-commison').textContent = '$' + monthly.toLocaleString();

            document.getElementById('year-1-commison').textContent = '$' + yearly.toLocaleString();
            document.getElementById('year-2-commison').textContent = '$' + commisonTwoYear.toLocaleString();
            document.getElementById('year-3-commison').textContent = '$' + commisonThreeYear.toLocaleString();
            document.getElementById('year-4-commison').textContent = '$' + commisonFourYear.toLocaleString();

        }
        function initSlider() {
            const range = document.getElementById("range-visitor");
            const bubble = document.getElementById("range-bubble");
            range.addEventListener("input", () => {
                setBubble(range, bubble);
                calculateVisitors(range.value);
                if (range.value < 100001){
                    document.getElementById('insufficient-traffic').setAttribute("hidden", true);
                    document.getElementById('insuff-msg').textContent = "Insufficient traffic for beta";

                    document.getElementById('year-1-commison').setAttribute("hidden", true);
                    document.getElementById('dash-1').textContent = "--";

                    document.getElementById('year-2-commison').setAttribute("hidden", true);
                    document.getElementById('dash-2').textContent = "--";

                    document.getElementById('year-3-commison').setAttribute("hidden", true);
                    document.getElementById('dash-3').textContent = "--";

                    document.getElementById('year-4-commison').setAttribute("hidden", true);
                    document.getElementById('dash-4').textContent = "--";
                }
                else if (range.value == 100000000){
                    document.getElementById('insufficient-traffic').setAttribute("hidden", true);
                    document.getElementById('insuff-msg').textContent = "Contact Us for the estimate.";

                    document.getElementById('year-1-commison').setAttribute("hidden", true);
                    document.getElementById('dash-1').textContent = "--";

                    document.getElementById('year-2-commison').setAttribute("hidden", true);
                    document.getElementById('dash-2').textContent = "--";

                    document.getElementById('year-3-commison').setAttribute("hidden", true);
                    document.getElementById('dash-3').textContent = "--";

                    document.getElementById('year-4-commison').setAttribute("hidden", true);
                    document.getElementById('dash-4').textContent = "--";

                }
                else {
                    document.getElementById('insufficient-traffic').removeAttribute("hidden");
                    document.getElementById('insuff-msg').textContent = "";

                    document.getElementById('year-1-commison').removeAttribute("hidden");
                    document.getElementById('dash-1').textContent = "";

                    document.getElementById('year-2-commison').removeAttribute("hidden");
                    document.getElementById('dash-2').textContent = "";

                    document.getElementById('year-3-commison').removeAttribute("hidden");
                    document.getElementById('dash-3').textContent = "";

                    document.getElementById('year-4-commison').removeAttribute("hidden");
                    document.getElementById('dash-4').textContent = "";
                }
            });
            setBubble(range, bubble);
            calculateVisitors(range.value)

        }

        function setBubble(range, bubble) {
            const val = range.value;
            const min = range.min ? range.min : 0;
            const max = range.max ? range.max : 100;
            const newVal = Number(((val - min) * 100) / (max - min));
            bubble.innerHTML = formatNumber(val) + " Monthly Uniques";

            // Sorta magic numbers based on size of the native UI thumb
            bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
        }

        function formatNumber(num, precision = 2) {

            const map = [
                { suffix: 'T', threshold: 1e12 },
                { suffix: 'B', threshold: 1e9 },
                { suffix: 'M', threshold: 1e6 },
                { suffix: 'K', threshold: 1e3 },
                { suffix: '', threshold: 1 },
            ];

            const found = map.find((x) => Math.abs(num) >= x.threshold);
            if (found) {

                const formatted = Number((num / found.threshold).toFixed(precision));
                return formatted.toString() + found.suffix;
            }

            return num;

        }
        initSlider();
    }
}
</script>

<style scoped>
input[type="range"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none; 
    /* creating a custom design */
    width: 100%;
    cursor: pointer;
    outline: none;
    /*  slider progress trick  */
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #f1b548;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
    height: 20px;
    background: #373d47;
    border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
    height: 20px;
    background: #373d47;
    border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none; 
    /* creating a custom design */
    height: 20px;
    width: 20px;
    background-color: #FCB223;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.7);
    cursor: ew-resize;
    /*  slider progress trick  */
    box-shadow: -1007px 0 0 1000px #FCB223;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
    height: 20px;
    width: 20px;
    background-color: #FCB223;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.7);
    cursor: ew-resize;
    /*  slider progress trick  */
    box-shadow: -1007px 0 0 1000px #FCB223;
}

</style>